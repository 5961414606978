/* eslint-disable react/jsx-key */
import React, { lazy } from 'react'
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute'
import { Route } from 'react-router-dom'

const SignIn = lazy(() => import('../pages/SignIn/SignIn'))
const SignUp = lazy(() => import('../pages/SignUp/SignUp'))
const PasswordReset = lazy(() => import('../pages/PasswordReset/PasswordReset'))
// const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'))
  
const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'))
const Control = lazy(() => import('../pages/Control'))
const Home = lazy(() => import('../pages/Home'))
// const Trends = lazy(() => import('../pages/Trends/Trends'))
const Trends = lazy(() => import('../pages/Trends/'))
const Alarm = lazy(() => import('../pages/Alarm/Alarm'))
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'))
const About = lazy(() => import('../pages/About'))
const Server = lazy(() => import('../pages/Server/Server'))
const Site = lazy(() => import('../pages/Site/Site'))
const User = lazy(() => import('../pages/User/User'))
const Model = lazy(() => import('../pages/Model/Model'))
const Log = lazy(() => import('../pages/Log/Log'))

const routes = [
  <UnauthorizedRoute path="/signin" redirectTo="/" exact component={SignIn} />,
  <UnauthorizedRoute path="/signup" redirectTo="/" exact component={SignUp} />,
  <UnauthorizedRoute path="/guest" redirectTo="/" exact component={Dashboard} />,
  // <UnauthorizedRoute path="/page_not_found" redirectTo="/" exact component={PageNotFound} />,
  <UnauthorizedRoute
    path="/password_reset"
    redirectTo="/"
    exact
    component={PasswordReset}
  />,
  <Route path="/about" exact component={About}/>,
  <AuthorizedRoute path="/account" exact component={MyAccount} />,
  <AuthorizedRoute path="/control" exact component={Control} />,
  <AuthorizedRoute path="/home" exact component={Home} />,
  <AuthorizedRoute path="/trends" exact component={Trends} />,
  <AuthorizedRoute path="/alarm" exact component={Alarm} />,
  <AuthorizedRoute path="/dashboard" exact component={Dashboard} />,
  <AuthorizedRoute path="/server" exact component={Server} />,
  <AuthorizedRoute path="/site" exact component={Site} />,
  <AuthorizedRoute path="/user" exact component={User} />,
  <AuthorizedRoute path="/model" exact component={Model} />,
  <AuthorizedRoute path="/log" exact component={Log} />,
]

export default routes
